<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :isFilterRequiredForExport="true"
      :isWarehouseRequired="true"
      :skipAddButton="true"
      :overrideActionsWith="actions"
      :recordDetailComponent="cycleCountDetails"
      @makeRecount="makeRecount"
      @assignUser="assignUser"
      @exportTasks="exportTasks"
      @closeTask="closeTask"
      @makeCount="makeCount"
    >
      <template v-slot:topActions="{ permissions }">
        <CycleCountPanel
          v-if="permissions('create')"
          :page-loading="setPageLoading"
          :refresher="updateTableData"
        />
      </template>
    </DataTable>
    <AssignUser ref="assignUser" :page-loading="setPageLoading" />
    <!-- ScanItem with dynamic form fields -->
    <ActionsComponent ref="scanItem" />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
  SET_ACTION_TYPE,
  UPDATE_EXPORT_TASKS,
  CLOSE_CYCLE_COUNT,
} from "@/core/services/store/cycleCounts.module";
import CycleCountPanel from "@/own/components/warehouseManagement/cycleCounts/CycleCountPanel.vue";
import AssignUser from "@/own/components/warehouseManagement/cycleCounts/AssignUser.vue";
import CycleCountDetails from "@/own/components/warehouseManagement/cycleCounts/CycleCountDetails.vue";
import ActionsComponent from "@/own/components/warehouseManagement/cycleCounts/ActionsComponent.vue";
import Swal from "sweetalert2";
import SwalService from "@/core/services/swal.service";

export default {
  name: "CycleCounts",
  components: {
    DataTable,
    CycleCountPanel,
    AssignUser,
    ActionsComponent,
  },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getCYCLECOUNTSTableFilters",
      getTableState: "getCYCLECOUNTSTableState",
      getTableData: "getCYCLECOUNTSTableData",
      getTableHeaders: "getCYCLECOUNTSTableHeaders",
      getTableProperties: "getCYCLECOUNTSTableProperties",
      getTableExportUrl: "getCYCLECOUNTSExportUrl",
      getTablePermissions: "getCYCLECOUNTSTablePermissions",
    },
    cycleCountDetails: CycleCountDetails,
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
    },
    setPageLoading(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    updateTableData() {
      this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      });
    },
    makeCount(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$store.commit(SET_ACTION_TYPE, "scan");
      this.$refs.scanItem.toggleModal();
    },
    makeRecount(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$store.commit(SET_ACTION_TYPE, "recount");
      this.$refs.assignUser.toggleModal();
    },
    assignUser(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$store.commit(SET_ACTION_TYPE, "assign");
      this.$refs.assignUser.toggleModal();
    },
    exportTasks(item) {
      this.$store.dispatch(UPDATE_EXPORT_TASKS, item.id).then(() => {
        const link = document.createElement("a");
        link.href = this.$store.getters.getCYCLECOUNTSTaskExportUrl;
        link.download = "Export.csv";
        link.click();
        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        }, 1500);
        link.remove();
      });
    },
    closeTask(item) {
      if (!item) {
        return;
      }
      SwalService.warningConditionMessage(
        {
          title: SwalService.titles.are_you_sure,
          html: SwalService.messages.you_wont_be_able_to_revert_this,
        },
        () => {
          this.setPageLoading(true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            cycle_count_number: item.cycle_count_number,
          };
          this.$store
            .dispatch(CLOSE_CYCLE_COUNT, payload)
            .then(() => {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: `Cycle Count Task Closed`,
                timer: 2500,
              });
            })
            .then(() => {
              this.$store.dispatch(UPDATE_TABLE_DATA, {
                ...this.table_state,
                warehouse_id: this.selectedWarehouse,
              });
            });
        }
      );
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    actions: function () {
      return [
        {
          name: "count",
          title: "Count",
          type: "normal",
          method: "makeCount",
          isVisible: true,
        },
        {
          name: "recount",
          title: "Recount",
          type: "normal",
          method: "makeRecount",
          isVisible: true,
        },
        {
          name: "assign_user",
          title: "Assign User",
          type: "normal",
          method: "assignUser",
          isVisible: true,
        },
        {
          name: "export_tasks",
          title: "Export Tasks",
          type: "normal",
          method: "exportTasks",
          isVisible: true,
        },
        {
          name: "close",
          title: "Close",
          type: "normal",
          method: "closeTask",
          isVisible: true,
        },
      ];
    },
  },
  beforeMount() {
    if (this.selectedWarehouse) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.initTableContent();
    }
  },
  watch: {
    selectedWarehouse: function (value) {
      if (value) {
        this.setPageLoading(true);
        this.initTableContent();
      }
    },
  },
};
</script>
